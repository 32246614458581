export class Completer<T> {
    public readonly promise: Promise<T>;

    private _complete?: (value: (PromiseLike<T> | T)) => void;
    private _reject?: (reason?: any) => void;

    private _state: 'Pending' | 'Fulfilled' | 'Rejected' = 'Pending';

    public get state() {
        return this._state;
    }

    public constructor() {
        this.promise = new Promise<T>((resolve, reject) => {
            this._complete = resolve;
            this._reject = reject;
        });
    }

    complete(value: (PromiseLike<T> | T)) {
        this._state = 'Fulfilled';
        if (this._complete) {
            this._complete(value);
        }
    }

    reject(reason?: any) {
        this._state = 'Rejected';
        if (this._reject) {
            this._reject(reason);
        }
    }
}

export default Completer;
